<template>
  <div>
    <v-dialog
      v-model="$store.state.dialog"
      persistent
      :overlay="false"
      max-width="900px"
      transition="dialog-transition"
    >
      <v-card flat>
        <v-card-title>
          <span class="headline">
            {{ $store.state.dialogType === "add" ? "اضافة" : "تعديل" }}
            مجموعة
          </span>

          <v-spacer></v-spacer>
          <v-btn icon @click="$store.state.dialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="mt-5">
          <v-form v-model="valid">
            <v-row  v-if="!$store.state.loading">
            <v-col cols="12" md="6">
              <v-text-field
                outlined
                label="العنوان"
                name="title"
                :rules="[$rules.required()]"
                v-model="inputs.data.title"
                placeholder="قم بإدخال العنوان"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6">
              <v-radio-group row label="الحالة:" v-model="inputs.data.status">
                <v-radio label="مفعل" value="enabled"></v-radio>
                <v-radio label="غير مفعل" value="disabled"></v-radio>
              </v-radio-group>
            </v-col>
            <v-col cols="6">
              <v-select
                :items="designs"
                label="التصميم"
                v-model="inputs.data.design"
                :rules="[$rules.required()]"
                item-text="title"
                item-value="id"
                outlined
              ></v-select>
            </v-col>
            <v-col cols="6">
              <v-select
                :items="cols"
                label="المساحة"
                v-model="inputs.data.cols"
                item-text="text"
                item-value="value"
                  :rules="[$rules.required()]"
                outlined
              ></v-select>
            </v-col>
            <v-col cols="12" md="12">
              <p class="mb-4">الوصف</p>
              <v-md-editor
                v-model="inputs.data.content"
                :text="text"
                height="400px"
              ></v-md-editor>
            </v-col>
            <v-col cols="12" md="12">
              <v-file-input
                label="الصورة"
                prepend-icon="mdi-camera"
                v-model="file"
                filled
                placeholder="قم بإدخال الصورة"
                outlined
                accept="image/*"
              ></v-file-input>
            </v-col>
            <v-col cols="12" md="12">
              <div class="d-flex justify-end">
                <v-btn :disabled="!valid" large @click="add" color="primary">
                  <v-icon left>mdi-content-save</v-icon>
                  حفظ
                </v-btn>
              </div>
            </v-col>
          </v-row>
          <div v-else class="d-flex justify-center mt-10 pt-10">
            <v-progress-circular indeterminate size="60"></v-progress-circular>
          </div>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
export default {
  data() {
    return {
      file: null,
      inputs: {
        data: {
          title: "",
          status: "enabled",
          content: "",
          photo: null,
          sequence: "",
          cols: "12",
          resturant: this.$store.state.resturant.id,
        },
      },
      loading: false,
      designs: [],
      text: "",
      isEdit: false,
      valid: false,
      cols: [
        {
          text: "نصف الصفحة",
          value: "6",
        },
        {
          text: "الصفحة كاملة",
          value: "12",
        },
        {
          text: "ثلاث ارباع الصفحة",
          value: "8",
        },
        {
          text: "ثلث الصفحة",
          value: "4",
        },
        {
          text: "ربع الصفحة",
          value: "3",
        },
      ],
    };
  },
  methods: {
    async add() {
      this.$store.commit("setLoading", true);
      if (this.file != null) {
        let res = await this.$service.uploadFile(this.file);
        this.inputs.data.photo = res.id;
      }
      if (this.$store.state.dialogType == "edit") {
        await this.$http.put(
          "/categories/" + this.$store.state.item.id,
          this.inputs
        );
      } else {
        await this.$http.post("/categories", this.inputs);
      }

      this.$root.$emit("refresh");
      this.$store.commit("setLoading", false);
      this.$store.state.dialog = false;
    },
  },
  async mounted() {
    // listen to the event
    let designs = await this.$http.get("/designs", {
      params: {
        filters: {
          resturant: {
            id: { $eq: this.$store.state.resturant.id },
          },
        },
      },
      paramsSerializer: this.$service.qsParams,
    });
    this.designs = designs.data.data;
    this.$root.$on("fill-fields", (data) => {
      console.log(data);
      if (data.content == null) {
        data.content = "";
      }
      Object.keys(data).forEach((key) => {
        this.inputs.data[key] = data[key];
      });
      if (data.photo != null) {
        this.inputs.data.photo = data.photo.id;
        this.file = new File([data.photo], data.photo.name, {
          type: data.photo.type,
        });
      }
    });
  },
  watch: {
    "$store.state.dialog"(val) {
      if (val == false) {
        this.inputs.data = {
          title: "",
          status: "enabled",
          content: "",
          photo: null,
          sequence: "",
          cols: "12",
          resturant: this.$store.state.resturant.id,
        };
      }
    },
  },
};
</script>

<template>
  <div>
    <v-card :loading="$store.state.loading" elevation="0" class="transparent">
      <v-card-title>
        <h3>المجموعات</h3>
        <v-spacer></v-spacer>
        <v-btn color="primary" @click="openAddDialog()">اضافة مجموعة</v-btn>
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col cols="12">
            <v-data-table
              :headers="headers"
              :ripple="false"
              :items="categories"
              hide-default-footer
              disable-pagination
              :loading="$store.state.loading"
              no-data-text="لا يوجد"
              loading-text="جار التحميل..."
            >
              <template v-slot:item.created="{ item }">
                {{ $service.formatDate(new Date(item.createdAt), false) }}
              </template>
              <template v-slot:item.status="{ item }">
                <v-chip
                  :color="item.status == 'enabled' ? 'success' : 'error'"
                  text-color="white"
                >
                  {{ item.status == "enabled" ? "مفعل" : "غير مفعل" }}
                </v-chip>
                <!-- {{ sub.status }} -->
              </template>
              <template v-slot:item.photo="{ item }">
                <v-btn
                  text
                  small
                  color="primary"
                  @click="showImage(item.photo)"
                >
                  <v-icon>mdi-eye</v-icon>
                </v-btn>
              </template>
              <template v-slot:item.productsCount="{ item }">
                {{ item.products.length }}
              </template>
              <template v-slot:item.actions="{ item }">
                <div class="d-flex align-center">
                  <v-btn color="primary" @click="openEditDialog(item)" outlined>
                    <v-icon>mdi-pencil</v-icon>
                    تعديل
                  </v-btn>
                  <v-btn
                    color="error"
                    text
                    @click="deleteItem(item.id, item.title)"
                  >
                    <v-icon>mdi-delete</v-icon>
                  </v-btn>
                </div>
              </template>
            </v-data-table>
          </v-col>
          <v-col cols="12">
            <div class="d-flex justify-center">
              <v-pagination
                v-model="options.pagination.page"
                :length="parseInt(total / options.pagination.pageSize) + 1"
              />
            </div>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <Add />
  </div>
</template>
<script>
import Add from "./Add.vue";
export default {
  components: { Add },
  data() {
    return {
      categories: [],
      total: 1,

      headers: [
        { text: "العنوان", value: "title" },
        { text: "الحالة", value: "status" },
        { text: "الصورة", value: "photo" },
        { text: "التصميم", value: "design.title" },
        { text: "عدد المنتجات", value: "productsCount" },
        { text: "التاريخ", value: "created" },
        { text: "الاجراءات", value: "actions" },
      ],
      options: {
        populate: "*",
        filters: {
          resturant: {
            id: { $eq: this.$store.state.resturant.id },
          },
        },
        pagination: {
          page: 1,
          pageSize: 15,
        },
      },
    };
  },
  created() {
    this.getnews();
    this.$root.$on("refresh", () => {
      this.getnews();
    });
  },
  methods: {
    getnews() {
      this.$store.commit("setLoading", true);
      this.$http
        .get("/categories", {
          params: this.options,
          paramsSerializer: this.$service.qsParams,
        })
        .then((response) => {
          this.categories = response.data.data;
          this.total = 1;
          this.$store.commit("setLoading", false);
        });
    },
    openAddDialog() {
      this.$store.state.dialog = true;
      this.$store.state.dialogType = "add";
    },
    openEditDialog(item) {
      this.$store.state.item = item;

      this.$root.$emit("fill-fields", item);
      this.$store.state.dialog = true;
      this.$store.state.dialogType = "edit";
    },
    deleteItem(id, title) {
      title;
      this.$store.commit("setLoading", true);
      this.$http.put("/categories/" + id, {
        data: {
          publishedAt: null,
        },
      });
      this.categories = this.categories.filter((item) => item.id != id);
      // this.getnews()
      this.$store.commit("setLoading", false);
    },
  },
  watch: {
    options: {
      handler() {
        this.getnews();
      },
      deep: true,
    },
  },
};
</script>
